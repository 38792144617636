<template>
  <div class="title-container" :style="titleContainer" ref="titleContainer">
    <div class="title-box" :style="titleBoxStyl">
      <p class="main-title" :style="mainTitle" ref="mainTitle">{{ data.setting[0].tempData.titleContentData.titleName }}</p>
      <p class="half-title" :style="halfTitle" ref="halfTitle">{{ data.setting[0].tempData.titleContentData.titleDes }}</p>
    </div>
    <div class="link-box">
      <a href="javascript:;" @click="clickLink">{{ data.setting[0].tempData.titleContentData.titleUrl }}</a>
    </div>
  </div>
</template>

<script>
import {commonClickFn} from '../../../modules/dynamic/block/componentArticle/commonModuleFn'
export default {
    name: 'titleBar',
    props:{
       data: {
           type: Object,
           default: ()=>{}
       }
    },
    computed:{
        titleContainer(){
          let {blockMargeTop, blockMargeBottom, blockBGClolor} = this.data.setting[0].tempData.specialStyle
            return {
              '--all-margin-top': `${blockMargeTop}px`,
              '--all-margin-bottom': `${blockMargeBottom}px`,
              '--all-background-color': blockBGClolor
            }
        },
        titleBoxStyl(){
          let {titleAlign } = this.data.setting[0].tempData.specialStyle
          return {
            "--title-link-padding": titleAlign == 'right'?'120px': ''
          }
        },
        mainTitle(){
            let {titleAlign, titleBold, titleColor, titleSize} = this.data.setting[0].tempData.specialStyle
            return {
                '--main-font-color': titleColor,
                '--main-font-size': `${titleSize || 24}px`,
                '--main-font-weight': titleBold? '700': '',
                '--main-text-align': titleAlign,
            }
        },
        halfTitle(){
            let {descSize, descColor, descBold, descAlign} = this.data.setting[0].tempData.specialStyle
            return {
                '--half-font-color': descColor,
                '--half-font-size': `${descSize || 14}px`,
                '--half-font-weight': descBold? '700': '',
                '--half-text-align': descAlign,
            }
        }

    },
  methods: {
    clickLink(){
      commonClickFn(this.data.setting[0].tempData.titleContentData, '', this, true)
    }
  }
};
</script>

<style lang="less" scoped>
.title-container {
  width: 1200px;
  // height: 93px;
  padding: 16px;
  box-sizing: border-box;
  background-color: pink;
  display: flex;
  align-content: center;
  position: relative;
  margin: 0 auto;
  margin-top: var(--all-margin-top);
  margin-bottom: var(--all-margin-bottom);
  background-color: var(--all-background-color);
  .title-box {
    width: 100%;
    padding-right: var(--title-link-padding);
    .main-title{
        margin-bottom: 8px;
        color: var(--main-font-color);
        font-size: var(--main-font-size);
        font-weight: var(--main-font-weight);
        text-align: var(--main-text-align);
    }
    .half-title{
        color: var(--half-font-color);
        font-size: var(--half-font-size);
        font-weight: var(--half-font-weight);
        text-align: var(--half-text-align);
    }
  }
  .link-box {
    height: 20px;
    position: absolute;
    top: 23px;
    right: 16px;
    a{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333330;
    }
  }
}
</style>